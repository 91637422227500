* {
	margin: 0;
	padding: 0;
	box-sizing: border-box; }

.clear {
	clear: both;
	height: 0;
	width: 0;
	overflow: hidden;
	font-size: 0;
	line-height: 0; }

body {
	background-color: $dark-tan;
	font-size: 62.5%;
	width: 100%;
	overflow-x: hidden;

	&.page {
		background: $light-tan url('#{$image_base}/background.jpg') 0 0 no-repeat;
		background-size: 100%;
		padding-top: 20px;

		@include breakpoint($small-screen) {
			padding-top: 10px; }

		.container {
			@include breakpoint($small-screen) {
				padding: 0 20px 20px; } }

		header {
			@include breakpoint($small-screen) {
				padding: 0 20px; }

			.container {
				@include breakpoint($small-screen) {
					padding: 0; } } } } }

.container {
	width: 100%;
	max-width: 960px;
	margin: 0 auto; }

.button {
	background-color: $light-red;
	border-radius: .5em;
	color: $white;
	text-decoration: none;
	display: inline-block;
	transition: all .5s ease;
	font-family: $laila;

	&:hover {
		background-color: $dark-red;
		color: $white; }

	&.button--white {
		background-color: $white;
		color: $dark-brown;
		background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(234,234,234,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f4f4f4',GradientType=0 );
		box-shadow: $shadow-color 0 1px 2px;

		&:hover {
			background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(214,214,214,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#cccccc',GradientType=0 );
			text-shadow: $white 0 2px 1px; } } }

#trigger {
	top: 50%;
	position: fixed; }


// inputs

.input-text {
	background-color: $white;
	padding: 10px;
	display: block;
	border: 1px solid $line-color;
	border-radius: .25em;
	width: 100%;
	font-family: $lato;
	font-size: 1.6em;
	color: $dark-gray;
	font-weight: 300;
	outline: none; }

.select-wrap {
	position: relative;
	display: block;

	.select-arrow {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		background: transparent url('#{$image_base}/select-arrow.jpg') 0 0 no-repeat;
		height: 7px;
		width: 14px;
		display: block;
		pointer-events: none; } }

.input-select {
	padding: 10px;
	display: block;
	border: 1px solid $line-color;
	border-radius: .25em;
	width: 100%;
	font-family: $lato;
	font-size: 1.6em;
	color: $dark-gray;
	font-weight: 300;
	outline: none;
	appearance: none; }

.input-textarea {
	background-color: $white;
	padding: 10px;
	display: block;
	border: 1px solid $line-color;
	border-radius: .25em;
	width: 100%;
	font-family: $lato;
	font-size: 1.6em;
	color: $dark-gray;
	font-weight: 300;
	outline: none;
	height: 130px; }

.input-date + i {
	pointer-events: none;

	&:after {
		font-family: 'icons', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: $light-gray;
		content: '\e901';
		display: inline-block;
		font-size: 1.8em;
		font-style: normal;
		position: absolute;
		top: 12px;
		right: 10px;
		transition: all .2s ease-in-out; } }

.input-date + i.hover {
	&:after {
		color: $line-color; } }

.input-checkbox:not(:checked),
.input-checkbox:checked {
	position: absolute;
	left: -9999px; }

.input-checkbox:not(:checked) + label,
.input-checkbox:checked + label {
	position: relative;
	padding-left: 35px;
	cursor: pointer; }

.input-checkbox:not(:checked) + label:before,
.input-checkbox:checked + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 23px;
	height: 23px;
	border: 1px solid $line-color;
	background: $white;
	border-radius: 3px;
	box-shadow: inset 0 1px 3px $shadow-color; }

.input-checkbox:not(:checked) + label:after,
.input-checkbox:checked + label:after {
	content: '✓';
	position: absolute;
	top: 6px;
	left: 5px;
	font-size: 1.4em;
	line-height: 0.8;
	color: $dark-red;
	transition: all .2s;
	outline: none; }

.input-checkbox:not(:checked) + label:after {
	opacity: 0;
	transform: scale(0); }

.input-checkbox:checked + label:after {
	opacity: 1;
	transform: scale(1); }

.input-checkbox:disabled:not(:checked) + label:before,
.input-checkbox:disabled:checked + label:before {
	box-shadow: none;
	border-color: $line-color;
	background-color: $off-white; }

.input-checkbox:disabled:checked + label:after {
	color: $dark-gray; }

.input-checkbox:disabled + label {
	color: $light-gray; }

.input-checkbox:checked:focus + label:before,
.input-checkbox:not(:checked):focus + label:before {
	border: 1px dotted $dark-red; }

.input-checkbox + label:hover:before {
	border: 1px solid $dark-red !important; }
