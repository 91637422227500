$border-radius: .2em;

section {
	&.sent-gifts {
		min-height: 500px;

		.title-wrap {
			position: relative;
			overflow: auto;

			a {
				position: absolute;
				top: 33px;
				right: 0;
				font-family: $laila;
				font-size: 1rem;
				transition: all .3s ease-in-out;
				padding: 5px;
				@extend .button; } }


		.page-title {
			@extend .page-title; }

		table {}
		table {
			border-collapse: separate;
			border-spacing: 0 10px;
			width: 100%;

			thead {
				th {
					text-align: left;
					font-size: 1rem;
					color: $dark-red;
					font-family: $laila;
					font-weight: normal;
					padding: 5px 10px; } }

			tbody {
				tr {
					td {
						text-align: left;
						background-color: $white;
						font-size: 1rem;
						font-family: $lato;
						padding: 5px 10px;
						color: $dark-gray;
						font-weight: normal;
						margin-bottom: 10px;
						max-width: 400px;
						line-height: 1.5;
						vertical-align: text-top;

						&:first-child {
							border-bottom-left-radius: $border-radius;
							border-top-left-radius: $border-radius; }

						&:last-child {
							border-bottom-right-radius: $border-radius;
							border-top-right-radius: $border-radius; }

						&.actions {
							text-align: right; }

						.edit {
							display: inline-block;
							background: transparent url('#{$image_base}/edit-icon.png') 0 0 no-repeat;
							height: 20px;
							width: 21px;
							text-indent: 9999px;
							overflow: hidden;
							padding: 5px;
							position: relative;
							top: 3px;
							margin-right: 10px; }

						.cancel {
							display: inline-block;
							background: transparent url('#{$image_base}/trash-icon.png') 0 0 no-repeat;
							height: 20px;
							width: 14px;
							text-indent: 9999px;
							overflow: hidden;
							padding: 5px;
							position: relative;
							top: 3px; } } } } } } }
