section {
	&.new-account {

		.page-title {
			font-family: $laila;
			font-size: 3.5em;
			font-weight: 300;
			background-image: none;
			color: $dark-red;
			text-align: center;
			margin: 40px 0 0 0; }

		form {
			width: 100%;
			max-width: 838px;
			margin: 40px auto;
			position: relative;
			background-repeat: no-repeat !important;

			@include breakpoint-min($small-screen) {
				background: transparent url('#{$image_base}/box-bg.png');
				padding: 210px 89px 73px 100px;
				margin-bottom: 80px; }

			@include breakpoint($small-screen) {

				.box-top {
					width: 100%;
					height: 28.8vw;
					max-height: 273px;
					background: transparent url('#{$image_base}/box-top.png') 0 0 no-repeat;
					background-size: 100%; }

				.box-center {
					padding: 0 9vw;
					background: transparent url('#{$image_base}/box-center.png') 0 0 repeat-y;
					background-size: 100%; }

				.box-bottom {
					background: transparent url('#{$image_base}/box-bottom.png') 0 0 no-repeat;
					max-height: 65px;
					height: 12vh;
					background-size: 100%; }

				.form-inner {
					position: relative;
					top: -2vh; } }

			.row {
				margin: 0 0 20px 0;
				clear: both;

				&:last-child {
					margin-bottom: 0; }

				label {
					width: 35%;
					display: block;
					float: left;
					font-size: 1.6em;
					color: $dark-brown;
					font-family: $laila;
					font-weight: 300;
					position: relative;
					top: 8px;

					&:after {
						content: ':'; }

					&.empty-label {
						&:after {
							text-indent: -9999px;
							overflow: hidden;
							display: block; } }

					@include breakpoint($mobile) {
						width: 100%;
						margin-bottom: 10px; } }

				.input-text {
					width: 65%;
					float: left;
					display: block;

					@include breakpoint($mobile) {
						width: 100%; } }

				.note {
					font-size: 1.2em;
					display: inline-block;
					margin: 3px;
					color: #705147;
					font-family: $laila; } }

			.submit {
				input {
					border: none;
					padding: 10px 15px;
					float: right;
					font-size: 1.4em;
					cursor: pointer; } } } } }
