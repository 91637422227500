#hamburger {
	width: 40px;
	height: 30px;
	position: absolute;
	right: 0;
	top: 28px;
	z-index: 605;
	transform: rotate(0deg);
	transition: .3s ease-in-out;
	cursor: pointer;
	display: none;

	span {
		display: block;
		position: absolute;
		height: 9px;
		width: 100%;
		background: $dark-brown;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0px; }

		&:nth-child(2),
		&:nth-child(3) {
			top: 15px; }

		&:nth-child(4) {
			top: 30px; } }

	&.open {
		span {
			&:nth-child(1) {
				top: 18px;
				width: 0;
				left: 50%; }

			&:nth-child(2) {
				transform: rotate(45deg); }

			&:nth-child(3) {
				transform: rotate(-45deg); }

			&:nth-child(4) {
				top: 18px;
				width: 0;
				left: 50%; } } }

	@include breakpoint($small-screen) {
		display: block; } }
