section {
	&.login {

		.form-inner {
			padding-top: 40px;

			@include breakpoint($mobile) {
				padding-top: 0; }

			.row {
				margin-bottom: 40px; } }

		.options {
			margin-top: 40px;
			padding: 80px 0 40px;
			border-top: 1px solid $light-gray;

			p {
				font-size: 1.6em;
				font-family: $lato;
				margin: 0 0 20px; } }

		a {
			color: $dark-red;
			transition: all .2s ease-in-out;

			&:hover {
				color: $light-red; } } } }
