section {
	&.faq {
		clear: both;
		//padding: 20px 0
		display: block;

		.container {
			position: relative; }

		.scroll-wrap {
			position: relative; }

		.page-title {
			@extend .page-title; }

		.left {
			width: 100%;
			padding-right: 320px;

			@include breakpoint($small-screen) {
				padding-right: 0; }

			dl {
				width: 100%;

				dt {
					color: $dark-red;
					font-family: $laila;
					font-weight: 400;
					font-size: 2.4em;
					margin-bottom: 10px;
					line-height: 1.3;
					word-wrap: break-word;
					width: 100%; }

				dd {
					margin-bottom: 40px;
					color: $dark-brown;
					font-family: $laila;
					font-weight: 400;
					font-size: 1.6em;
					line-height: 1.5;
					word-wrap: break-word;
					width: 100%;
					text-align: justify; } } }


		.right {
			position: absolute;
			top: 0;
			right: 0;
			width: 300px;
			padding: 10px;
			height: auto;

			@include breakpoint($small-screen) {
				position: relative;
				width: 100%;
				margin: 0 20px 40px 0; }

			.top {
				height: 24px;
				width: 100%;
				background: transparent url('#{$image_base}/sidebar-top.png') 0 0 no-repeat;
				background-size: 100%; }

			.content {
				background: transparent url('#{$image_base}/sidebar-bg.png') 0 0 repeat-y;
				background-size: 99%;
				overflow: scroll;
				padding: 0 10px;

				@include breakpoint($small-screen) {
					padding: 10px 20px 0 25px; } }

			.bottom {
				height: 17px;
				width: 100%;
				background: transparent url('#{$image_base}/sidebar-bottom.png') 0 0 no-repeat;
				background-size: 100%;
				margin-bottom: 100px; }

			ul {
				list-style: none outside;

				li {
					padding: 10px 5px 10px 40px;
					position: relative;
					width: 97%;

					&:hover {
						background-color: rgba(255,255,255,.12); }

					.icon-gift {
						position: absolute;
						top: 9px;
						left: 5px;

						&:before {
							font-family: 'icons', sans-serif;
							font-size: 1.2em;
							color: $white;
							opacity: .6;
							content: '\e900';
							padding-right: 10px;
							line-height: 1;
							position: relative;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale; } }

					a {
						color: $white;
						font-family: $lato;
						font-size: 1.6em;
						text-decoration: none;
						font-weight: 400;
						display: block;
						transition: all .2s ease;
						opacity: .8;

						&:hover {
							opacity: 1; } } } } } } }
