section {
	&.my-account {
		.container {
			position: relative;
			min-height: 450px;

			.boxes {
				list-style: none outside;
				width: 100%;
				box-sizing: border-box;
				overflow: auto;

				.box {
					width: 25%;
					padding: 38px 10px 10px 10px;
					box-sizing: border-box;
					float: left;
					background: transparent url('#{$image_base}/boxtop.svg') center top no-repeat;
					cursor: pointer;

					&:first-child {
						padding-left: 0; }

					&:last-child {
						padding-right: 0; }

					&:hover {

						.box-top {
							background-color: $medium-red;
							border-left: 1px solid rgba(255, 255, 255, 1); }

						.box-inner {
							box-shadow: none;
							border-left: 1px solid $white;
							border-bottom: 1px solid $white;

							a {
								color: $white;

								svg {
									fill: $white !important; } } } }

					@include breakpoint($mobile) {
						width: 50%;
						margin-bottom: 40px; }

					@include breakpoint($mobile-portrait) {
						width: 100%;
						margin-bottom: 40px; }

					.box-top {
						background-color: $dark-red;
						height: 50px;
						width: 100%;
						border-bottom: 2px solid rgba(0,0,0,.3);
						position: relative;
						z-index: 2;
						transition: all .5s ease-in-out;
						border-left: 1px solid $medium-red; }

					.box-inner {
						background-color: $light-red;
						min-height: 160px;
						box-sizing: border-box;
						margin: 0 auto;
						width: 96%;
						position: relative;
						top: -2px;
						z-index: 1;
						transition: all .3s ease-in-out;
						border-left: 1px solid $light-red;
						box-shadow: inset 0 0 15em $dark-red;

						a {
							display: inline-block;
							width: 100%;
							position: absolute;
							left: 50%;
							top: 50%;
							transform: translateY(-60%) translateX(-50%);
							color: $dark-red;
							text-decoration: none;
							font-size: 1.4rem;
							font-family: $laila;
							font-weight: normal;
							transition: all .3s ease;
							text-align: center;

							&:hover {
								color: $white; }

							svg {
								transition: all .3s ease;
								display: block;
								height: 53px;
								width: 45px;
								margin: 0 auto;
								fill: $dark-red;
								margin-bottom: 5px;
								stroke-width: 0;
								stroke: none; } } } } } } } }
