section {
	&.page {
		margin-bottom: 60px;

		.page-title {
			font-size: 3.5em;
			color: $dark-red;
			font-family: $laila;
			font-weight: 400;
			padding-bottom: 15px;
			margin: 30px 0 50px;
			background: transparent url(../img/line.png) bottom left repeat-x; } } }
