@font-face {
	font-family: 'icons';
	src:    url('#{$font_base}/icons.eot?wvnw5y');
	src:    url('#{$font_base}/icons.eot?wvnw5y#iefix') format('embedded-opentype'), url('#{$font_base}/icons.ttf?wvnw5y') format('truetype'), url('#{$font_base}/icons.woff?wvnw5y') format('woff'), url('#{$font_base}/icons.svg?wvnw5y#icons') format('svg');
	font-weight: normal;
	font-style: normal;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }
