// colors
$white: #ffffff;
$off-white: #eeedea;
$light-red: #d4735b;
$medium-red: #c86850;
$dark-red: #945040;
$light-tan: #ece7de;
$dark-tan: #d8d0c1;
$light-brown: #7c7364;
$dark-brown: #705147;
$line-color: #b9ab9e;
$light-gray: #dbd8d0;
$dark-gray: #7f7f7f;
$shadow-color: rgba(0,0,0,.3);
$text-color: #333;

$admin_gift_amount: #dde7e1;
$admin_gift_text_color: #515151;
$admin_gift_status: #9e0b0f;



// screen sizes
$small-screen: 1024px;
$mobile: 770px;
$mobile-portrait: 480px;

// fonts
$lato: 'Lato', helvetica, verdana, sans-serif;
$open-sans: 'Open Sans', helvetica, verdana, sans-serif;
$laila: 'Laila', helvetica, verdana, sans-serif;

// base paths for fonts and images
$image_base: '../../img'; // wp
$font_base: '../../fonts'; // wp

//$image_base: '../img' // dist
//$font_base: '../fonts' // dist

// mixins
@mixin breakpoint($point) {
	@media screen and (max-width: $point) {
		@content; } }

@mixin breakpoint-min($point) {
	@media screen and (min-width: $point) {
		@content; } }

// clear
.pclear {
	clear: both;
	&:before {
		content: '';
		height: 0;
		width: 0;
		overflow: hidden; } }

// paragraphs
.p {
	font-size: 1rem;
	font-family: $laila;
	font-weight: normal;
	line-height: 1.2;
	color: $dark-brown; }

// page titles
.page-title {
	font-size: 3.5em;
	color: $dark-red;
	font-family: $laila;
	font-weight: 400;
	padding-bottom: 15px;
	margin: 30px 0 50px;
	background: transparent url('#{$image_base}/line.png') bottom left repeat-x; }
