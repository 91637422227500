section {
	&.default-page {

		.page-title {
			font-size: 3.5em;
			color: $dark-red;
			font-family: $laila;
			font-weight: 400;
			padding-bottom: 15px;
			margin: 30px 0 50px;
			background: transparent url(../img/line.png) bottom left repeat-x; }

		p {
			font-size: 1.6em;
			font-family: $lato;
			margin-bottom: 20px;
			color: $dark-brown;
			line-height: 1.5;

			&:last-child {
				margin-bottom: 0; } }

		ul {
			list-style: outside disc;
			margin: 0 0 20px 20px;

			li {
				@extend p;
				margin-bottom: 5px; } }

		a {
			color: $dark-red;
			transition: all .2s ease-in-out;

			&:hover {
				color: $light-red; } }

		.heading {
			font-size: 2.4em;
			color: $dark-red;
			margin: 40px 0 20px;
			font-family: $laila;
			font-weight: 300; }

		address {
			@extend p;
			margin-bottom: 40px !important; } } }
