section {
	&.main {
		background: $light-tan url('#{$image_base}/background.jpg') 0 0 no-repeat;
		background-size: cover;
		background-position: center;
		padding: 20px 40px 40px;
		min-height: 600px;
		position: relative;

		.gradient {
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(236,233,226,0) 96%,rgba(235,232,225,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ebe8e1',GradientType=0); }


		@include breakpoint($small-screen) {
			padding: 10px 10px 40px; }

		.box {
			position: relative;
			z-index: 2;
			margin: 50px auto;
			width: 100%;
			max-width: 838px;
			//max-width: 440px

			.item {
				width: 100%;
				padding: 25% 12% 9% 15%;

				background: transparent url('#{$image_base}/box-bg.png') 0 0 no-repeat;
				background-size: cover;

				ul {
					list-style: none outside;
					max-width: 100%;

					li {
						a {
							img {
								max-width: 100%; } } } } } }

		.cta {
			position: relative;
			z-index: 2;

			p {
				max-width: 638px;
				font-family: $laila;
				font-size: 2.6em;
				line-height: 1.2;
				font-weight: 400;
				color: $light-brown;
				text-align: center;
				margin: 0 auto; }


			.button {
				margin: 40px auto 0;
				font-size: 1.4rem;
				width: auto;
				display: block;
				max-width: 175px;
				padding: 15px 0; } } }

	&.how-it-works {
		background: $light-red url('#{$image_base}/red-sep.jpg') 0 0 repeat-x;
		color: $white;
		padding: 100px 40px 40px;

		.title {
			background: transparent url('#{$image_base}/title-bg.jpg') left center no-repeat;
			background-size: contain;
			color: $white;
			font-size: 4vw;
			font-family: $laila;
			font-weight: 400;
			text-align: center;
			text-transform: uppercase;

			@include breakpoint($small-screen) {
				font-size: 6vw; } }

		.description {
			color: $white;
			font-size: 2em;
			font-family: $lato;
			font-weight: 300;
			line-height: 1.4;
			text-align: center;
			max-width: 80%;
			display: block;
			margin: 20px auto; }

		#timeline-wrap {
			height: auto;
			width: 100%; }

		.scrollContent {
			height: auto;
			width: 100%;
			position: relative; }

		.timeline {
			margin-top: 100px;
			list-style: none outside;
			min-height: 2120px;
			background: $light-red url('#{$image_base}/path.jpg') top center no-repeat;
			display: block;
			overflow: visible;

			@include breakpoint($small-screen) {
				margin-top: 0;
				background-repeat: repeat-y; }

			li {
				position: relative;
				float: left;
				width: 100%;
				overflow: visible;

				.num {
					display: inline-block;
					padding: 20px;
					border-radius: 50%;
					color: $white;
					font-size: 2em;
					font-family: $lato;
					line-height: .5;
					background-color: $dark-red;
					position: absolute;
					left: 50%;
					opacity: 0;

					@include breakpoint($small-screen) {
						position: relative;
						margin: 0 auto;
						left: 50% !important;
						top: auto;
						transform: translateX(-50%); } }

				.text {
					color: $white;
					padding-right: 40px;
					opacity: 0;

					@include breakpoint($small-screen) {
						margin: 20px 0;
						padding: 5px 0;
						background-color: $light-red; }

					p {
						font-size: 2em;
						font-family: $lato;
						font-weight: 300;
						color: $white; } }


				.text,
				.img {
					width: 50%;
					position: relative;
					float: left;
					transition: all .5s ease;

					@include breakpoint($small-screen) {
						float: none !important;
						width: 100%;
						text-align: center;
						margin-bottom: 20px;
						top: auto !important;
						padding-left: 0 !important;
						padding-right: 0 !important; }

					img {
						max-width: 100%;
						transition: all .5s ease;
						opacity: 0;
						position: relative;
						right: -100%;

						@include breakpoint($small-screen) {
							display: block;
							margin: 0 auto;
							width: auto;
							float: none !important; } } }

				&:nth-child(2n+2) {
					.text,
					.img {
						float: right;

						img {
							right: auto;
							left: -100%; } }

					.text {
						padding-right: 0;
						padding-left: 40px; } }


				&.item-1 {
					@include breakpoint($small-screen) {
						margin-top: 75px; }

					.text {
						position: relative;
						top: 135px; }

					.img {
						padding-left: 90px;

						img {
							float: right;
							position: relative;
							top: 20px;
							opacity: 0; } }

					.num {
						top: 50%;
						left: 50%;
						opacity: 0; } }

				&.item-2 {
					margin-top: 100px;

					.num {
						top: 50%;
						left: 43%; }

					.img {
						padding-right: 90px; }

					.text {
						position: relative;
						top: 115px; } }

				&.item-3 {
					margin-top: 100px;

					@include breakpoint($small-screen) {
						margin-top: 40px; }

					.card {
						position: absolute;
						height: 282px;
						width: 273px;
						background: transparent url('#{$image_base}/card.png') 0 0 no-repeat;
						top: 280px;
						left: 140px;

						&.swing {
							-webkit-transform-origin: top right !important;
							-ms-transform-origin: top right !important;
							transform-origin: top right !important; }

						@include breakpoint($small-screen) {
							display: none; } }

					.num {
						top: 63%;
						left: 43%; }



					.img {
						padding-left: 120px; }

					.text {
						position: relative;
						top: 215px;
						padding-right: 200px; } }

				&.item-4 {
					margin-top: 300px;

					@include breakpoint($small-screen) {
						margin-top: 40px; }

					.num {
						top: 33%;
						left: 44%; }

					.img {
						padding-right: 90px; }

					.text {
						position: relative;
						top: 100px;
						padding-left: 100px; } }

				&.item-5 {
					margin-top: 100px;

					@include breakpoint($small-screen) {
						margin-top: 40px; }

					.num {
						top: 57%;
						left: 44%; }

					.img {
						padding-left: 90px; }

					.text {
						position: relative;
						top: 170px;
						padding-right: 100px; } } } } }

	&.footer-cta {
		padding: 100px 0;
		text-align: center;
		background-color: $light-tan;
		color: $light-brown;

		p {
			font-size: 2em;
			line-height: 1.5;
			font-family: $lato;
			color: $light-brown;
			max-width: 50%;
			margin: 0 auto;

			@include breakpoint($small-screen) {
				max-width: 80%; } }

		.button {
			font-family: $laila;
			display: inline-block;
			padding: 10px;
			font-size: 2em;
			margin-top: 40px; } } }
