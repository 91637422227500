header {
	position: relative;
	z-index: 2;

	.container {
		height: 75px;
		border-bottom: 1px solid $line-color;
		overflow: visible;
		position: relative; }

	.logo {
		width: 228px;
		height: 78px;
		float: left;

		a {
			display: block;
			width: 228px;
			height: 78px;
			overflow: hidden;
			text-indent: -9999px;
			background: transparent url('#{$image_base}/logo.png') 0 0 no-repeat; } }

	nav {
		float: right;

		@include breakpoint($small-screen) {
			display: none; }

		ul {
			list-style: none outside;
			text-align: right;
			margin-top: 30px;

			li {
				display: inline-block;
				margin-right: 10px;
				font-family: $laila;
				font-size: 1.8em;

				&:last-child {
					a {
						@extend .button;
						margin-left: 20px; } }

				a {
					color: $dark-brown;
					text-decoration: none;
					padding: 5px 10px;
					transition: all .5s ease;

					&:hover {
						color: $light-red; } }

				&.current {
					a {
						color: $light-red; } } } }
		&.open {
			display: block;
			float: none;
			width: 100%;

			ul {
				text-align: left;

				li {
					display: block; } } } }

	.slider {
		display: block;
		height: 1px;
		background-color: $line-color;
		transition: all .5s ease;
		width: 10px;
		position: absolute;
		right: 0;
		top: 74px;

		&.active {
			height: 3px;
			top: 73px;
			background-color: $light-red; }

		&.learn-more {
			right: 303px;
			width: 97px; }

		&.faq {
			right: 217px;
			width: 56px; }

		&.login {
			right: 137px;
			width: 47px; }

		&.schedule {
			right: 362px;
			width: 130px; }

		&.my-account {
			right: 228px;
			width: 100px; }

		&.faq-logged-in {
			right: 140px;
			width: 56px; } } }


nav.mobile {
	transition: all 1s ease;
	height: 0;
	top: -26px;
	position: relative;
	width: 100%;
	overflow: hidden;
	z-index: 2;

	@include breakpoint-min($small-screen) {
		display: none !important; }

	ul {
		list-style: none outside;
		text-align: left;
		margin-top: 30px;

		li {
			display: block;
			font-family: $laila;
			font-size: 1.8em;
			padding: 10px 0;
			border-bottom: 1px solid $line-color;

			&:last-child {
				border-bottom: none;

				a {
					@extend .button;
					width: 100%;
					display: block;
					margin: 10px auto 0;
					text-align: center;
					padding: 10px 0; } }

			a {
				color: $dark-brown;
				text-decoration: none;
				padding: 5px 10px;
				transition: all .5s ease;
				display: block;

				&:hover {
					color: $light-red; } } } }
	&.open {
		display: block;
		height: auto;
		overflow: visible; } }
