footer {
	background-color: $light-gray;
	overflow: auto;
	padding: 20px;
	position: relative;

	@include breakpoint($mobile) {
		padding-bottom: 40px; }

	@include breakpoint($mobile-portrait) {
		padding-bottom: 85px; }

	.logo {
		width: 100%;
		max-width: 164px;
		height: 57px;
		background: transparent url('#{$image_base}/footer-logo.jpg') 0 0 no-repeat;
		background-size: contain;
		overflow: hidden;
		float: left;

		@include breakpoint($small-screen) {
			max-width: 100px;
			position: relative;
			top: 10px; }

		a {
			display: block;
			height: 57px;
			width: 164px;
			overflow: hidden;
			text-indent: -9999px; } }

	nav {
		position: absolute;
		left: 50%;
		top: 45px;
		transform: translateX(-50%);
		width: 100%;
		max-width: 600px;

		@include breakpoint($mobile) {
			max-width: 100%;
			top: auto;
			bottom: 10px; }

		@include breakpoint($mobile-portrait) {
			bottom: 25px; }

		ul {
			list-style: none outside;
			text-align: center;
			margin-left: 10px;

			@include breakpoint($small-screen) {
				margin-left: 0; }

			li {
				display: inline-block;
				padding: 0 15px;
				line-height: 2;

				@include breakpoint($small-screen) {
					padding: 0 7px; }

				@include breakpoint($mobile) {
					padding: 0 10px; }

				&:first-child {
					padding-left: 0; }

				&:last-child {
					padding-right: 0; }

				a {
					font-family: $lato;
					font-size: 1.4em;
					font-weight: 400;
					color: $dark-gray;
					text-decoration: none;
					text-transform: uppercase;
					transition: all .2s ease-in-out;

					@include breakpoint($mobile) {
						font-size: 1.2em; }

					&:hover {
						color: $light-red; } } } } }

	.social {
		float: right;
		list-style: none outside;
		top: 15px;
		position: relative;

		li {
			display: inline-block;
			height: 33px;
			width: 33px;
			margin-left: 20px;

			@include breakpoint($small-screen) {
				margin-left: 10px; }

			&:first-child {
				margin-left: 0; }

			a {
				height: 33px;
				width: 33px;
				display: block;
				overflow: hidden;
				background: transparent url('#{$image_base}/social-sprite.jpg') 0 0 no-repeat;
				text-indent: -9999px;
				transition: all .2s ease;

				&:hover {
					background-position: 0 -33px; } }

			&.twitter {
				a {
					background-position: -33px 0;

					&:hover {
						background-position: -33px -33px; } } }

			&.gplus {
				a {
					background-position: -66px 0;

					&:hover {
						background-position: -66px -33px; } } } } }

	&.li {
		font-size: 93%; } }
