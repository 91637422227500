section {
	&.recipient {
		.page-title {
			font-size: 3.5em;
			color: $dark-red;
			font-family: $laila;
			font-weight: 400;
			padding-bottom: 15px;
			margin: 30px 0 50px;
			background: transparent url('#{$image_base}/line.png') bottom left repeat-x; }

		label {
			color: $dark-brown;
			font-family: $laila;
			font-size: 1.6em;
			cursor: pointer;
			outline: none;

			&.required {
				&:before {
					content: '*';
					margin-right: 5px;
					position: relative;
					top: 6px; } }

			small {
				font-size: .7rem;
				font-family: $lato;
				color: $dark-brown;
				font-style: italic;
				display: inline-block;
				margin-left: 20px; } }

		.info-top {
			padding: 15px;
			border: 1px solid $line-color;
			background-color: $off-white;
			border-radius: .5em;
			overflow: auto;
			margin-top: 20px;

			p {
				width: 20%;
				float: left;
				padding-right: 20px;

				&:nth-child(-n+2) {
					width: 30%; }

				&:last-child {
					padding-right: 0; }

				@include breakpoint($mobile) {
					width: 100% !important;
					padding: 0;
					margin-bottom: 10px; } } }

		.info-middle {
			overflow: auto;

			.col {
				width: 100%;
				max-width: 33.333333%;
				padding-right: 20px;
				float: left;

				@include breakpoint($mobile) {
					max-width: 100% !important; }

				&:last-child {
					padding-right: 0; }

				&.col-1 {
					label {
						display: block;

						&:after {
							content: ':'; } }

					.input-date + i {
						&:after {
							top: 29px; } } }

				&.col-2 {
					h2 {
						margin-bottom: 49px; } }

				&.col-3 {
					h2 {
						margin-bottom: 73px; } }

				&.col-2,
				&.col-3 {
					label {
						top: 3px; }
					p {
						margin-bottom: 10px; } }

				h2 {
					font-size: 3em;
					color: $dark-red;
					font-family: $laila;
					font-weight: 400;
					padding-bottom: 15px;
					margin: 30px 0 50px;

					@include breakpoint($mobile) {
						margin-bottom: 10px !important; }

					span {
						display: block;
						font-size: 50%; } }

				p {
					position: relative;
					margin-bottom: 20px; } } }

		.info-bottom {
			margin-top: 40px;

			fieldset {
				border: none;
				background-color: $light-red;
				padding: 20px;
				margin: 10px 0 40px;
				border-radius: .5em;

				ul {
					list-style: none outside;
					overflow: visible;
					display: flex;
					flex-wrap: wrap;
					flex-direction: row;

					li {
						display: inline-block;
						text-align: center;
						width: 16.6666667%;
						float: left;
						align-self: baseline;
						overflow: visible;

						@include breakpoint($mobile) {
							width: 100% !important;
							margin: 20px 0; }

						&:hover {
							.icon {
								&:before {
									color: $dark-red;
									transition: all .5s ease;
									text-shadow: $white 0 0 20px; } } }

						label {
							display: block;
							color: $white;
							font-size: 1.6em;
							font-family: $lato; }

						span {
							display: block; }

						.input-checkbox {
							display: block; }

						.icon {
							display: block;
							font-family: 'icons', sans-serif;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							color: $dark-brown;
							line-height: 1;
							margin-bottom: 10px;

							&:before {
								content: "\e900";
								line-height: 1; }

							&.icon-gift-amount-25:before {
								font-size: 2em; }

							&.icon-gift-amount-50:before {
								font-size: 2.4em; }

							&.icon-gift-amount-75:before {
								font-size: 2.8em; }

							&.icon-gift-amount-100:before {
								font-size: 3.2em; }

							&.icon-gift-amount-125:before {
								font-size: 3.6em; }

							&.icon-gift-amount-150:before {
								font-size: 4em; } } } } }


			p {
				position: relative;

				label {
					display: inline-block;
					margin-bottom: 10px; }

				&.required-text {
					color: $dark-red;
					font-family: $lato;
					font-size: 1.6em;
					font-weight: 300;
					margin: 20px 0 20px;
					font-style: italic; } }

			.button-wrap {
				padding-top: 20px;
				background: transparent url('#{$image_base}/line.png') top left repeat-x;
				margin-bottom: 40px;
				overflow: visible;

				@include breakpoint($mobile) {
					margin-bottom: 10px; }

				.button {
					padding: 10px 15px;
					font-size: 1.6em;
					margin-right: 10px;

					@include breakpoint($mobile) {
						width: 100%;
						text-align: center;
						display: block;
						margin-bottom: 20px; } } } }

		.save-button {
			float: right;
			margin-right: 0;
			border: none;
			cursor: pointer;

			@include breakpoint($mobile) {
				float: none;
				margin-bottom: 0 !important; } } } }

div.error {
	background-color: darkred;
	text-align: center;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;

	p {
		color: white;
		font-size: 1.2rem;
		line-height: 1.4;
		font-family: $laila;
		text-transform: uppercase; } }

div.success {
	@extend div.error;
	background-color: darkgreen; }
