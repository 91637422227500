#order-info {
	min-height: 500px;
	padding-top: 20px;

	.order-specific {
		width: 50%;
		box-sizing: border-box;
		padding: 0 20px 40px 0;
		float: left; }

	.recipient-specific {
		width: 50%;
		box-sizing: border-box;
		float: right;
		padding: 0 20px 40px 0; }

	h2 {
		font-family: $laila;
		font-size: 1.5rem;
		color: $dark-red;
		font-weight: normal;
		margin: 20px 0;

		&.order-number {
			margin-bottom: 0; } }

	.order-status {
		margin-bottom: 20px;
		font-size: 1rem;
		color: $dark-gray;
		font-family: $laila;
		font-weight: normal;
		padding-top: 15px; }

	.order-date {
		@extend .p; }

	.expected-delivery {
		overflow: auto;
		margin-top: 20px;

		.left {
			display: inline-block;
			box-sizing: border-box;
			vertical-align: text-top;
			max-width: 50%;
			float: left;
			p {
				@extend .p; } }

		.right {
			display: inline-block;
			max-width: 50%;
			box-sizing: border-box;
			padding-left: 20px;
			float: left;

			p {
				@extend .p;
				font-size: .9rem; } } }

	.order-amount {
		@extend .p;
		margin-top: 20px;

		span {
			margin-left: 10px;

			&:before {
				content: '$';
				display: inline-block;
				margin-right: 1px; }

			&:after {
				content: '.00';
				text-decoration: underline;
				font-size: .5rem;
				top: -5px;
				display: inline-block;
				position: relative; } } }

	.card-text {
		margin-top: 20px;
		@extend .p;

		p {
			margin-top: 10px;
			padding-left: 20px;
			box-sizing: border-box; } }

	.recipient {
		p {
			@extend .p; }

		address {
			@extend .p;
			margin-bottom: 20px; } }

	.birthday {
		@extend .p;
		margin-bottom: 20px; }

	.additional-info {
		@extend .p;
		margin-bottom: 20px;

		p {
			box-sizing: border-box;
			padding-left: 20px;
			margin-top: 10px; } }

	.special-interests {
		@extend .p;

		ul {
			margin: 10px 0 0 20px;

			li {

				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0; } } } } }
