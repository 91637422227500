.heading_base {
	color: $dark-red;
	font-family: $lato;
	font-weight: normal;
	line-height: 1;
	margin: 20px 0 10px; }

.the-content {
	p {
		color: $text-color;
		font-size: 1rem;
		font-family: $lato;
		font-weight: normal;
		line-height: 1.4;
		margin: 0 0 20px; }

	h1 {
		@extend .heading_base;
		font-size: 2rem; }

	h2 {
		@extend .heading_base;
		font-size: 1.8rem; }

	h3 {
		@extend .heading_base;
		font-size: 1.6rem; }

	h4 {
		@extend .heading_base;
		font-size: 1.4rem; }

	h5 {
		@extend .heading_base;
		font-size: 1.2rem; }

	h6 {
		@extend .heading_base;
		font-size: 1rem; }

	ul {
		list-style: disc outside;
		margin: 20px 0 20px 35px; }

	ol {
		list-style: decimal-leading-zero;
		margin: 20px 0 20px 35px; }

	li {
		@extend p; }

	pre {
		@extend p;
		font-size: 1.3rem; }

	a {
		color: $light-red;
		border-bottom: 1px dashed $dark-red;
		transition: all .4s ease-in-out;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			color: $dark-red;
			border-bottom: 1px solid $light-red; } } }
